$(document).ready(function() {
    var $prodVariant = $('#sylius-product-selecting-variant');
    if (!$prodVariant.length) return;

    var $prodForm = $prodVariant

    var $tierTable = $prodVariant.find('#tier_prices_tables');
    if (!$tierTable.length) return;

    var $cartItemQuantity = $prodVariant.find('#sylius_add_to_cart_cartItem_quantity');
    var tiers = [];

    $tierTable.find('.tier-preset').each(function (index, el) {
        var $el = $(el);
        tiers.push({
            qty: $el.data('qty'),
            price: $el.data('price'),
        });
    });

    $tierTable.find('.tier-row input[name="tier"]').change(function(ev) {
        var $target = $(ev.target);
        var $tierRow = $target.closest('.tier-row');
        if ($tierRow.hasClass('tier-preset')) {
            $cartItemQuantity.val($tierRow.data('qty'));
        }
        else {
            $cartItemQuantity.val($tierRow.find('input.custom-qty').val());
        }
    });

    $tierTable.find('.tier-row input.custom-qty').focus(function(ev) {
        var $target = $(ev.target);
        $target.closest('.tier-row').find('input[name="tier"]').prop('checked', true).change();
    });

    $tierTable.find('.tier-row input.custom-qty').blur(function(ev) {
        var $target = $(ev.target);
        $target.closest('.tier-row').find('input[name="tier"]').prop('checked', true).change();
    });

    $cartItemQuantity.closest('.quantity-wrapper').hide();
    $tierTable.find('.tier-row:eq(0) input[name="tier"]').prop('checked', true).change();

    function refreshCustomPrice(ev) {
        var $target = $(ev.target);
        var targetQty = parseInt($target.val());

        var matchedTier = tiers[tiers.length - 1];
        for (var i = tiers.length - 1; i >= 0; i--) {
            var currTier = tiers[i];
            if (targetQty > currTier.qty) {
                break;
            }
            matchedTier = currTier;
        }

        var price;
        if (matchedTier === tiers[tiers.length - 1] && matchedTier.qty < targetQty) {
            price = Math.ceil((targetQty / matchedTier.qty) * matchedTier.price);
        }
        else {
            price = matchedTier.price;
        }

        price /= 100;

        $target.closest('.tier-row').find('.price-label.excl-tax').html(price.toLocaleString('pl-PL', { style: 'currency', currency: 'PLN' }));
        $target.closest('.tier-row').find('.price-label.incl-tax').html((price * 1.23).toLocaleString('pl-PL', { style: 'currency', currency: 'PLN' }));
    }

    $tierTable.find('.tier-custom input.custom-qty').keyup(refreshCustomPrice);
    $tierTable.find('.tier-custom input.custom-qty').change(refreshCustomPrice);
    $tierTable.find('.tier-custom input.custom-qty').trigger('change');
});
